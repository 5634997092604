import * as React from "react"
import {Helmet} from "react-helmet"

const NotFoundPage = () => {
    return (
        <main className="bg-light">
            <Helmet
                bodyAttributes={{
                    class: "overflow-hidden"
                }}
            >
                <meta charSet="utf-8"/>
                <title>Preview</title>
                <link rel="canonical" href="https://fulfilled.app"/>
            </Helmet>

            <div className="container-xxl g-0 bg-white border">
                <div className="row g-0">

                    <div className="col vh-100 d-flex align-items-center justify-content-center border-start-0">
                        <h1 className="text-secondary">
                            Missing Preview
                        </h1>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NotFoundPage
